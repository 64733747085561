<template>
    <div class="project-create">
        <div class="manage-wrapper">
            <div class="project-create-container">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="创建报告" name="first">
                        <el-form ref="createForm" :model="form" label-width="180px" :rules="rules">
                            <el-form-item label="报告名称：" prop="title" required>
                                <el-input v-model="form.title" maxlength="100" show-word-limit style="width:400px">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="报告类型：" prop="type" required>
                                <el-radio-group v-model="form.type">
                                    <el-radio v-for="(type, index) in typeOptions" :key="index" :label="type.value">
                                        {{ type.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="报告角色：" prop="roleIds" required>
                                <el-checkbox :indeterminate="form.isIndeterminate" v-model="form.checkAll"
                                    @change="handleCheckAllChange">全选</el-checkbox>
                                <el-checkbox-group v-model="form.roleIds" @change="handleCheckedRolesChange">
                                    <el-checkbox v-for="role in rolesOptions" :label="role.id" :key="role.id">
                                        {{ role.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="模块1：">
                                <el-checkbox v-model="form.checkPhase">我的观思问</el-checkbox>
                                <div v-if="form.checkPhase">
                                    观思问时间：<br>
                                    <el-date-picker v-model="form.phaseDateTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.phaseSubmitteTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="模块2：">
                                <el-checkbox v-model="form.checkQuest">我的作业</el-checkbox>
                                <div v-if="form.checkQuest">
                                    <el-transfer v-model="form.worksIds" :data="workList" :titles="['全部作业', '已选作业']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按作业名称搜索"
                                        :props="{ key: 'questId', label: 'questName' }">
                                        <template #default="{ option }">
                                            <!-- 使用 el-tooltip 包裹长文本项 -->
                                            <el-tooltip :content="option.questName" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.questName }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                    作业时间：<br>
                                    <el-date-picker v-model="form.questDateTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期" @change="timeChange">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.questSubmitteTime" type="datetimerange"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <!-- 所属项目与用户组 -->
                            <UserGroup v-if="form.type == 3" :id="Number($route.params.id)" @userGroup="changeUserGroup"
                                :isBoutique="form.type">
                            </UserGroup>
                            <el-form-item label="AI模板：" prop="description" required>
                                <!-- <upload @uploadFile="handleFileChange" :name="'identifier'" :text="'上传AI模板'" /> -->
                                <el-select v-model="modelVal" placeholder="请选择模板" style="margin-bottom: 10px;"
                                    @change="selectChange">
                                    <el-option v-for="item in modelList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <br>
                                <el-input class="textarea" type="textarea" :autosize="{ minRows: 7 }" placeholder="生成报告描述"
                                    v-model="form.description">
                                </el-input>
                            </el-form-item>
                            <!-- 按钮组 -->
                            <el-form-item class="el-form-item-btns" label-width="0px">
                                <el-button @click="$router.push('/home/totalReport')">取消</el-button>
                                <el-button type="primary" @click="onSubmit">确定</el-button>
                            </el-form-item>
                            <br />
                            <br />
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import upload from './upload-file.vue';
export default {
    components: {
        upload
    },
    data() {
        return {
            activeName: "first",
            form: {
                projectId: null, //项目id
                title: null, // 报告名称
                type: null, // 报告类型  0周总结 1盟市总结 2教委总结 3个人总结
                roleIds: [], // 角色
                checkPhase: null, // 观思问模块选择  0未选择 1已选择
                phaseDateTime: null, // 观思问时间
                phaseDateStart: null, // 观思问开始时间
                phaseDateEnd: null, // 观思问结束时间
                phaseSubmitteTime: null, // 观思问答题开始时间
                phaseSubmitteStart: null, // 观思问答题开始时间
                phaseSubmitteEnd: null, // 观思问答题结束时间
                checkQuest: null, // 作业块选择  0未选择 1已选择
                questDateStart: null, // 作业开始时间
                questDateEnd: null, // 作业结束时间
                questSubmitteStart: null, // 作业答题开始时间
                questSubmitteEnd: null, // 作业答题结束时间
                userGroupIds: null, // 用户组id
                description: null, // AI描述
                checkAll: false,
                isIndeterminate: true,
                worksIds: [], // 穿梭框选择的作业id
            },
            // 表单验证规则
            rules: {
                title: [{
                    required: true,
                    message: "请填写报告名称",
                    trigger: "blur"
                }],
                type: [{
                    required: true,
                    message: "请选择报告类型",
                    trigger: "change"
                }],
                roleIds: [{
                    required: true,
                    message: "请选择报告角色",
                    trigger: "change"
                }],
                description: [{
                    required: true,
                    message: "请填写报告描述",
                    trigger: "blur"
                }]
            },
            rolesOptions: [],
            typeOptions: [],
            fileMd5List: [],
            // fileList: [],
            workList: [],
            modelVal: '',
            modelList: [
                {
                    value: '1',
                    label: '模板1 三段九环培训个人周学习报告(第**周)'
                }, {
                    value: '2',
                    label: '模板2 行动改进计划'
                }, {
                    value: '3',
                    label: '模板3 高频词'
                }, {
                    value: '4',
                    label: '模板4 给专家的观思问报告模板'
                }
            ]
        };
    },
    methods: {
        // 上传文件
        handleFileChange(md5List) {
            console.log('回调List', md5List)
            this.fileMd5List = md5List;

        },
        // 切换用户组
        changeUserGroup(data) {
            console.log(data);
            this.form.trancheId = data.trancheId;
            this.form.projectId = data.projectId;
            this.form.userGroupIds = data.userGroupArr;
            console.log(this.form);
        },
        handleCheckAllChange(val) {
            console.log(val)
            if (val) {
                this.rolesOptions.map(item => {
                    this.form.roleIds.push(item.id);
                })
            } else {
                this.form.roleIds = [];
            }
            this.form.isIndeterminate = false;
        },
        handleCheckedRolesChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.form.checkAll = checkedCount === this.rolesOptions.length;
            this.form.isIndeterminate = checkedCount > 0 && checkedCount < this.rolesOptions.length;
            // console.log('this.form.isIndeterminate',this.form.isIndeterminate)
        },
        // 提交表单
        async commitForm() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let submitForm = {
                title: this.form.title,
                type: this.form.type,
                projectId: this.$store.state.user.currentProject.id,
                description: this.form.description,
                descriptionFiles: this.fileMd5List,
                questIds: this.form.checkQuest ? this.form.worksIds.toString() : '',
                modelId: this.modelVal
            }
            this.$set(submitForm, 'roleIds', this.form.roleIds.toString())
            if (this.form.phaseDateTime) {
                this.$set(submitForm, 'phaseDateStart', this.form.phaseDateTime[0])
                this.$set(submitForm, 'phaseDateEnd', this.form.phaseDateTime[1])
                this.$set(submitForm, 'phaseSubmitteStart', this.form.phaseSubmitteTime[0])
                this.$set(submitForm, 'phaseSubmitteEnd', this.form.phaseSubmitteTime[1])
            }
            this.$set(submitForm, 'checkPhase', this.form.checkPhase ? 1 : 0)
            this.$set(submitForm, 'checkQuest', this.form.checkQuest ? 1 : 0)
            if (this.form.type == 3) {
                submitForm.groupString = JSON.stringify(this.form.userGroupIds)
            }

            console.log('submitForm', submitForm);
            let resData = await this.$Api.Project.createReport(submitForm);
            console.log(resData);
            if (resData.code == 200) {
                loading.close();
                this.$notify.success({
                    title: "创建成功",
                    // message: resData.msg
                });

                this.$router.push({
                    path: `/manager/report/create_kimi?breadNumber=4`,
                    query: {
                        reportId: resData.data.reportId,
                        rootMsgId: resData.data.rootMsgId,
                        breadNumber: 4,
                        reportType: this.form.type,
                        isView: false
                    }
                })
            } else {
                loading.close();
                this.$notify.error({
                    title: "创建失败",
                    message: resData.msg
                });
            }
        },
        //创建课程
        async onSubmit() {
            this.$refs['createForm'].validate((valid) => {
                // console.log('valid',valid);
                if (valid) {
                    this.commitForm();
                } else {
                    this.$message.error({
                        title: "错误",
                        message: "请正确填写数据"
                    });
                }
            })
        },
        // 获取报告类型Option
        async getReportOption() {
            let pramas = {
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportOption(pramas);
            console.log('选项数据', resData.data);
            this.typeOptions = resData.data.type;
            this.rolesOptions = resData.data.role;
        },
        // 获取穿梭框作业列表
        async listByProject() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                startTime: this.form.questDateTime ? this.form.questDateTime[0] : '',
                endTime: this.form.questDateTime ? this.form.questDateTime[1] : ''
            }
            let resData = await this.$Api.Project.listByProject(data);
            console.log('作业列表:', resData);
            resData.data.map(item => {
                item.disabled = false
            })
            this.workList = resData.data
        },
        // 筛选作业时间
        timeChange() {
            this.listByProject()
        },
        selectChange(val) {
            console.log(`%c ${val}`, 'color:green');
            if (val == 1) {
                this.form.description = `1.日志内容完整性： 评价学员日志是否包含所有必要的听思问元素或者观思问要素\n2.思考的相关度与深度： 评价学员在日志中展现的思考与反思相关度与深度，每天每周的内容是否相关，是否对所听内容进行了深入分析，是否进行了自我剖析\n3.问题探索性： 评价学员提出的问题是否具有探索性，能否激发进一步的思考或讨论
4.学习与实践应用结合： 所涉及内容与自身实践应用相结合的能力\n5.表达清晰性： 评价学员在日志中的表达是否清晰、有逻辑`
            } else if (val == 2) {
                this.form.description = `根据 以下标准 一、目标与策略：30分\n 1、行动改进计划的目标是否明确与量化；\n2、行动改进计划的执行策略是否与目标相关；\n3、预期目标、行动改进计划、行动改进策略相互支持 \n二、执行与资源保证：30分 \n资源配置是否合理，符合逻辑 团队协作是否能够达成，能辐射多大范围 资源保证是否可行\n 三、监测、评价与反馈：20分 \n1、是否有过程性的评价与监测制度 \n2、评价反馈是否及时或者有调整空间 \n四、持续改进与发展：20分 \n1、行动改进的持续性与发展性，\n 2、是否进行持续改进，\n 3、是否有进一步发展空间\n对每个人的行动改进计划分别进行分析，分别生成每个人的分析报告，并根据分值进行打分
                `
            } else if (val == 3) {
                this.form.description = `分析每个人观思问内容的关键词，并根据出现的词频规律进行关注点分析，然后按照关注程度从最关注到最不关注进行排列，并单独统计关键词出现的次数。并按照：关键词，出现次数，分析三项内容进行展示，换行显示，注意只需要统计3个字以上的关键词。`
            } else {
                this.form.description = `1、列举骨干老师在教学和专业发展方面的十个共性需求问题，并进行分析：\n2、 在上述共性需求的基础上，列举十个方面的具体的个性化需求，并注明每个个性需求的具体学员名单。`
            }
        }
    },
    created() { },
    mounted() {
        this.getReportOption();
        this.listByProject()
    }
};
</script>

<style lang="less" scoped>
@import "../../manage/manage_project/less/manage-project.less";
</style>